<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useEmitAsProps
} from 'radix-vue'

import { cn } from '@/lib/utils'

const props = withDefaults(
  defineProps<
    DialogContentProps & {
      class?: string
      overlayClass?: string
      closeable?: boolean
      label?: string
      datacy?: string
    }
  >(),
  {
    class: '',
    overlayClass: '',
    closeable: true,
    label: '',
    datacy: ''
  }
)
const emits = defineEmits<DialogContentEmits>()

const emitsAsProps = useEmitAsProps(emits)
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      :class="
        cn(
          'fixed inset-0 z-50 bg-white/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
          overlayClass ?? ''
        )
      "
    />
    <DialogContent
      :class="
        cn(
          'fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-gray-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full',
          props.class
        )
      "
      :datacy
      v-bind="{ ...props, ...emitsAsProps }"
    >
      <slot />

      <slot name="close">
        <DialogClose
          v-if="closeable"
          class="absolute right-3 top-3 rounded-md p-0.5 transition-colors hover:bg-gray-100"
          :datacy="label ? `dialog-close-${label}` : 'dialog-close'"
        >
          <rq-icon icon="lucide:x" />
          <span class="sr-only">Close</span>
        </DialogClose>
      </slot>
    </DialogContent>
  </DialogPortal>
</template>
