<script lang="ts">
import type { ExposureType } from '@/capability/exposure/types'

export type Props = {
  items: ExposureType[]
  errors: string[]
}
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { cn, twMerge } from '@/lib/utils'
import { truncateText } from '@/lib/utils/formatting'

import InputDetails from '@/component/arqu-components/inputs/InputDetails.vue'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/component/arqu-components/shadcn/ui/dropdown-menu'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  errors: () => []
})

const selectedExposures = defineModel<ExposureType[]>({ default: [] })

const TRUNCATE_LENGTH = 48

const open = ref<boolean>(false)
function handleOpen(e: boolean) {
  if (e) {
    open.value = e
  }
}

const displayText = computed(() => {
  if (!selectedExposures.value.length) {
    return 'Select Multiple'
  }
  return props.items
    .filter((i) => !!i.name && selectedExposures.value.some((e) => e.name === i.name))
    .map((i) => i.title)
    .join(', \n')
})

function isSelected(exposure: ExposureType): boolean {
  return selectedExposures.value.some((e) => e.name === exposure.name)
}

function handleSelect(exposure: ExposureType) {
  if (isSelected(exposure)) {
    selectedExposures.value = selectedExposures.value.filter((d) => d.name !== exposure.name)
  } else {
    selectedExposures.value = [...selectedExposures.value, exposure]
  }
}
</script>

<template>
  <div>
    <Label>Selected Exposures</Label>
    <DropdownMenu :open="open" @update:open="handleOpen">
      <DropdownMenuTrigger as-child>
        <rq-btn
          id="exposure-type-select"
          :class="twMerge('flex w-full items-center justify-between', selectedExposures.length ? 'text-gray-950' : 'text-gray-500')"
          datacy="select-exposure"
          :disabled="!items.length"
          :style="{ border: '1px solid rgb(229 231 235) !important' }"
          variant="outline"
        >
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger as-child>
                <span>{{ truncateText(displayText, TRUNCATE_LENGTH) }}</span>
              </TooltipTrigger>
              <TooltipContent align="start" class="max-w-[400px] flex-wrap">{{ displayText }}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <rq-icon icon="lucide:chevron-down" />
        </rq-btn>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" :class="cn('max-h-64 w-full overflow-y-auto')" @interact-outside="open = false">
        <DropdownMenuCheckboxItem
          v-for="item in items"
          :key="item.name"
          :checked="isSelected(item)"
          :datacy="`listbox-item-${item.title}`"
          :value="item.name"
          @click.prevent="handleSelect(item)"
        >
          {{ item.title }}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
    <InputDetails v-if="errors.length > 0" id="selected-exposure-type-errors" :errors="errors" />
  </div>
</template>

<style scoped lang="scss"></style>
