export default [
  {
    path: '/carrier',
    component: () => import('@/component/open/OpenViewWrapper.vue'),
    children: [
      {
        path: 'risk-narrative',
        name: 'RiskNarrative-Carrier',
        component: () => import('@/views/open/OpenRiskNarrative.vue'),
        meta: {
          requiresAuth: true,
          title: 'Risk Narrative'
        }
      },
      {
        path: 'documents',
        name: 'Documents-Carrier',
        component: () => import('@/views/open/OpenDocumentRepo.vue'),
        meta: {
          requiresAuth: true,
          pageViewDetails: {
            pageId: 'carrier-view',
            resourceType: 'carrier',
            resourceCrudl: 'view'
          },
          title: 'Carrier Document View'
        }
      },
      {
        path: 'documents/preview/:docId',
        name: 'DocumentPreview-Carrier',
        component: () => import('@/views/open/OpenDocumentPreview.vue'),
        meta: {
          requiresAuth: true,
          pageViewDetails: {
            pageId: 'open-document-preview',
            resourceType: 'document',
            resourceCrudl: 'preview'
          },
          title: 'Document Preview'
        }
      },
      {
        path: 'risk-attributes-statement-of-values',
        name: 'RiskStatementOfValues-Carrier',
        alias: 'arqu%20SOV',
        component: () => import('@/views/open/OpenStatementOfValueSpreadsheet.vue'),
        meta: {
          title: 'Statement of Values',
          pageViewDetails: {
            pageId: 'risk-attribute-sov',
            resourceType: 'risk-attribute',
            resourceCrudl: 'sov'
          },
          ruleName: 'deal-read-info', // todo(jeff): should I create custom rules for this functionality?
          requiresAuth: true,
          access: {
            roles: ['carrier']
          }
        }
      }
    ]
  },
  {
    path: '/carrier/deals',
    component: () => import('@/views/carrier/CarrierDealListWrapper.vue'),
    children: [
      {
        path: '',
        name: 'DealListView-Carrier',
        component: () => import('@/views/deal/list/DealList.vue'),
        meta: {
          pageViewDetails: {
            pageId: 'deal-carrier-list',
            resourceType: 'deal',
            resourceCrudl: 'list',
            title: 'Deal Hub'
          },
          requiresAuth: true,
          title: 'Deal Hub',
          access: {
            roles: ['carrier']
          }
        }
      }
    ]
  }
]
