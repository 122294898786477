<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'
import { useAcl } from 'vue-simple-acl/src'

import { getLogger } from '@/composables/util/log/logger'
import { useNotificationStore } from '@/stores/notification'

import type { DealModel } from '@/capability/deal/types/deal-model'
import type { ExposureModel } from '@/capability/exposure/types'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { programService } from '@/capability/program/ProgramService'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import AddExposureDialog from '@/component/exposure/AddExposureDialog.vue'
import ProgramPublishToRetailer from '@/component/program/program-navbar/ProgramPublishToRetailer.vue'

const props = defineProps({
  deal: {
    type: Object as PropType<DealModel>,
    required: true
  },
  programModel: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  exposuresModel: {
    type: Array as PropType<ExposureModel[]>,
    required: false,
    default: () => []
  }
})

const emit = defineEmits<{
  (e: 'update:exposuresModel', payload: ExposureModel[]): void
}>()

const acl = useAcl()
const notificationStore = useNotificationStore()
const logger = getLogger('ProgramActions.vue')

const exposures = computed({
  get() {
    return props.exposuresModel as ExposureModel[]
  },
  set(newValue: ExposureModel[]) {
    emit('update:exposuresModel', newValue as ExposureModel[])
  }
})

async function exportAsExcel() {
  try {
    const response = await programService.exportAsExcel({ program: props.programModel! })
    const blob = response.data
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const a = document.createElement('a')
      const dateStr = new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(Date.now())
      a.download = props.deal!.clientInfo?.name + '-' + props.programModel!.title + '-' + dateStr + '.xlsx'
      a.href = e.target?.result as string
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  } catch (err) {
    notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
  }
}

// const exportAsPdf = async () => {
//   try {
//     const response = await programService.exportAsPdf(props.programModel)
//     const blob = response.data
//     const reader = new FileReader()
//     reader.readAsDataURL(blob)
//     reader.onload = (e: ProgressEvent<FileReader>) => {
//       const a = document.createElement('a')
//       const dateStr = new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(Date.now())
//       a.download = props.deal.clientInfo?.name + '-' + props.programModel.title + '-' + dateStr + '.pdf'
//       a.href = e.target?.result as string
//       document.body.appendChild(a)
//       a.click()
//       document.body.removeChild(a)
//     }
//   } catch (e) {
//     notificationStore.publishOneOrMoreErrUnhandled(e)
//   }
// }
</script>

<template>
  <div class="flex items-center space-x-1 pl-2">
    <AddExposureDialog v-if="acl.anyRole(['admin', 'rs'])" v-model:exposures="exposures" :program="programModel" />

    <ProgramPublishToRetailer
      v-if="acl.anyRole(['admin', 'rs'])"
      :deal="deal"
      :organization-id="deal.retailerInfo?.organizationId ?? ''"
      :program-name="programModel.title as string"
    />
    <TooltipProvider :delay-duration="100">
      <Tooltip>
        <TooltipTrigger as-child>
          <rq-btn
            v-if="acl.anyRole(['admin', 'rs', 'retailer'])"
            id="programs-navbar-export-as-excel-button"
            class="bg-pink-400 hover:bg-pink-500"
            datacy="export-to-excel"
            icon="square"
            size="lg"
            variant="ghost"
            @click="exportAsExcel"
          >
            <rq-icon color="white" icon="lucide:file-spreadsheet" />
          </rq-btn>
        </TooltipTrigger>
        <TooltipContent>Export as Excel</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
</template>

<style scoped lang="scss"></style>
