export default {
  primary: {
    DEFAULT: '#907cff',
    'darken-1': '#674dff',
    'darken-max': '#555770',
    'lighten-1': '#a899ff',
    'lighten-max': '#f4f2ff',
    50: '#f4f2ff',
    100: '#eae8ff',
    200: '#d8d4ff',
    300: '#bab2ff',
    400: '#907cff',
    500: '#7755fd',
    600: '#6732f5',
    700: '#5820e1',
    800: '#4a1abd',
    900: '#3e189a'
  },
  secondary: {
    DEFAULT: '#f1997e',
    'darken-1': '#ed805e',
    'lighten-1': '#f5b6a3'
  },
  tertiary: '#70aeff',
  panel: {
    DEFAULT: '#f9fbff', // $rqcore-bg-white-very-light
    'darken-1': '#c6d9ff',
    'darken-2': '#adc8ff',
    'darken-3': '#9fb7ff',
    'darken-4': '#7aa6ff'
  },
  aligned: '#617084',
  misaligned: '#a68383',
  info: '#617084',
  success: '#50b446',
  warning: '#ee9a6b',
  'rq-black': '#4a4b4d',
  'rq-light-black': '#77787a',
  'rq-grey': '#8a96a8',
  'rq-pink-btn': '#f180bd',
  'rq-green-btn': '#50b446',
  anchor: '#ff0000',
  'accordion-pill-color': '#635b8c',
  'accordion-base': '#d8dee7',
  'accordion-1': '#e6ebf2',
  'accordion-2': '#f0f3f7',
  'accordion-section': '#c6cfdd',
  'white-light': '#f4f7ff',
  'section-header': '#f8f8f8',
  'deal-gray': '#a4aebbff',
  'deal-gray-lighten': '#e1e5e9',
  'deal-gray-darken': '#4c5766',
  'deal-orange': '#ed9c6cff',
  'deal-orange-lighten': '#f9e0d1',
  'deal-orange-darken': '#9f4713',
  'deal-blue': '#70aeffff',
  'deal-blue-lighten': '#cce2ff',
  'deal-blue-darken': '#004db2',
  'deal-red': '#ed6c71ff',
  'deal-red-lighten': '#f9d1d3',
  'deal-red-darken': '#9f1318',
  accent: '#6557b3',
  'deal-green': {
    DEFAULT: '#93d773',
    lighten: '#e0f3d7',
    darken: '#478a27',
    50: '#f7fdf5',
    100: '#f0faeb',
    200: '#e0f3d7',
    300: '#b8e8a5',
    400: '#93d773',
    500: '#7bc65e',
    600: '#63b549',
    700: '#52943b',
    800: '#478a27',
    900: '#2f5e1a',
    950: '#1f3e12'
  }
}
