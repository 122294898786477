import type { App, InjectionKey, VNode } from 'vue'
import { createVNode, render } from 'vue'

import DialogConfirmComponent from '@/component/dialog/DialogConfirmComponent.vue'

export interface DialogConfirmOptions {
  icon?: string
  title?: string
  description?: string
  okText?: string
  cancelText?: string
  parentContext?: any
  appContext?: any
  onOk?: () => void | Promise<void>
  onCancel?: () => void | Promise<void>
  onClose?: () => void | Promise<void>
  cardClass?: string
  descriptionClass?: string
  displayCancel?: boolean
  datacy?: string
}

export type FunctionDialogConfirm = (options: DialogConfirmOptions) => Promise<boolean>

export const DialogConfirm: InjectionKey<FunctionDialogConfirm> = Symbol('confirmModal')

export const dialogConfirm = (app: App<Element>) => async (options: DialogConfirmOptions) => {
  const container = document.createElement('div')
  let vm: VNode | null = null
  const response: boolean = await new Promise((resolve) => {
    vm = createVNode(
      DialogConfirmComponent,
      {
        ...options,
        resolve
      },
      null,
      undefined
    )
    vm.appContext = options.parentContext || options.appContext || app._context
    render(vm, container)
  })
  // isOpen.value = false
  // vm.props.isOpen = false

  return response
}
