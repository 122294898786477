import { DealActivityType } from '@/capability/deal/types/activity'
import type { DealModel } from '@/capability/deal/types/deal-model'
import { DealDtoDealProgressEnum } from 'typescript-core-api-client/dist/api'

import type { BadgeVariantType } from '@/component/arqu-components/shadcn/ui/badge'

export function getVariant(dealProgress: DealDtoDealProgressEnum = 'Unknown'): BadgeVariantType {
  switch (dealProgress) {
    case 'RFClearance':
      return 'deal-orange'
    case 'Submitted':
      return 'deal-blue'
    case 'Indicated':
      return 'deal-blue-dark'
    case 'Quoted':
      return 'deal-teal'
    case 'BindAuthorized':
    case 'BindOrdered':
    case 'BindRequested':
    case 'BindConfirmed':
      return 'deal-green'
    case 'Declined':
    case 'Dead':
    case 'DeadRFClearance':
    case 'DeadQuoted':
    case 'DeadIndicated':
    case 'DeadSubmitted':
    case 'DeadBindRequested':
    case 'DeadDeclined':
    case 'Blocked':
      return 'deal-red'
    default:
      return 'deal-gray'
  }
}

export function getTextColor(dealProgress: DealDtoDealProgressEnum = 'Unknown'): string {
  if (['Active'].includes(dealProgress)) {
    return 'text-indigo-700'
  }
  if (['Sample'].includes(dealProgress)) {
    return 'text-fuchsia-700'
  }
  if (['RFClearance'].includes(dealProgress)) {
    return 'text-deal-orange-darken'
  }
  if (['Submitted', 'Indicated', 'Quoted'].includes(dealProgress)) {
    return 'text-deal-blue-darken'
  }
  if (['BindRequested', 'BindAuthorized', 'BindOrdered', 'BindConfirmed'].includes(dealProgress)) {
    return 'text-deal-green-darken'
  }
  if (
    [
      'Declined',
      'Dead',
      'DeadRFClearance',
      'DeadSubmitted',
      'DeadIndicated',
      'DeadQuoted',
      'DeadBindRequested',
      'DeadDeclined',
      'Blocked'
    ].includes(dealProgress)
  ) {
    return 'text-deal-red-darken'
  }
  return 'text-deal-gray-darken'
}

export function getBorderColor(dealProgress: DealDtoDealProgressEnum = 'Unknown'): string {
  if (['RFClearance'].includes(dealProgress)) {
    return 'border-deal-orange-darken'
  }
  if (['Submitted', 'Indicated', 'Quoted'].includes(dealProgress)) {
    return 'border-deal-blue-darken'
  }
  if (['BindRequested', 'BindAuthorized', 'BindOrdered', 'BindConfirmed'].includes(dealProgress)) {
    return 'border-deal-green-darken'
  }
  if (
    [
      'Declined',
      'Dead',
      'DeadRFClearance',
      'DeadSubmitted',
      'DeadIndicated',
      'DeadQuoted',
      'DeadBindRequested',
      'DeadDeclined',
      'Blocked'
    ].includes(dealProgress)
  ) {
    return 'border-deal-red-darken'
  }
  return 'border-deal-gray-darken'
}

const dealDtoDealProgressKeyToLabelMap = new Map<DealDtoDealProgressEnum, string>([
  [DealDtoDealProgressEnum.Inactive, 'Inactive'],
  [DealDtoDealProgressEnum.Sample, 'Sample'],
  [DealDtoDealProgressEnum.Active, 'Pre-Marketing'],
  [DealDtoDealProgressEnum.RfClearance, 'Request for Clearance'],
  [DealDtoDealProgressEnum.Submitted, 'Submitted'],
  [DealDtoDealProgressEnum.Indicated, 'Indicated'],
  [DealDtoDealProgressEnum.Quoted, 'Quoted'],
  [DealDtoDealProgressEnum.BindRequested, 'Bind Requested'],
  [DealDtoDealProgressEnum.BindAuthorized, 'Bind Authorized'],
  [DealDtoDealProgressEnum.BindOrdered, 'Bind Ordered'],
  [DealDtoDealProgressEnum.BindConfirmed, 'Bind Confirmed'],
  [DealDtoDealProgressEnum.Declined, 'Declined'],
  [DealDtoDealProgressEnum.Dead, 'Dead'],
  [DealDtoDealProgressEnum.DeadRfClearance, 'Dead Request for Clearance'],
  [DealDtoDealProgressEnum.DeadSubmitted, 'Dead Submitted'],
  [DealDtoDealProgressEnum.DeadIndicated, 'Dead Indicated'],
  [DealDtoDealProgressEnum.DeadQuoted, 'Dead Quoted'],
  [DealDtoDealProgressEnum.DeadBindRequested, 'Dead Bind Requested'],
  [DealDtoDealProgressEnum.DeadDeclined, 'Dead Declined'],
  [DealDtoDealProgressEnum.Blocked, 'Blocked']
])

const retailerDealDtoDealProgressKeyToLabelMap = new Map<DealDtoDealProgressEnum, string>([
  [DealDtoDealProgressEnum.Sample, 'Sample'],
  [DealDtoDealProgressEnum.Active, 'Pre-Marketing'],
  [DealDtoDealProgressEnum.RfClearance, 'Pre-Marketing'],
  [DealDtoDealProgressEnum.Submitted, 'Submitted'],
  [DealDtoDealProgressEnum.Indicated, 'Indicated'],
  [DealDtoDealProgressEnum.Quoted, 'Quoted'],
  [DealDtoDealProgressEnum.BindRequested, 'Proposed'],
  [DealDtoDealProgressEnum.BindAuthorized, 'Bind Requested'],
  [DealDtoDealProgressEnum.BindOrdered, 'Ordered Bound'],
  [DealDtoDealProgressEnum.BindConfirmed, 'Bound'],
  [DealDtoDealProgressEnum.Declined, 'Declined'],
  [DealDtoDealProgressEnum.Blocked, 'Blocked']
])

export function tryGetDealProgressLabel(item: DealDtoDealProgressEnum, retailer: boolean = false): string | DealDtoDealProgressEnum {
  const retailerItem = retailerDealDtoDealProgressKeyToLabelMap.get(item)
  const regularItem = dealDtoDealProgressKeyToLabelMap.get(item)
  if (!retailer || !retailerItem) return regularItem ?? item
  return retailerItem
}

const dealActivityKeyToLabelMap = new Map<DealActivityType, string>([
  [DealActivityType.DEAL_CREATED, 'Deal: Created'],
  [DealActivityType.DEAL_UPDATED, 'Deal: Updated'],
  [DealActivityType.DEAL_INDICATED, 'Deal: Indicated'],
  [DealActivityType.DEAL_QUOTED, 'Deal: Quoted'],
  [DealActivityType.DEAL_DECLINED, 'Deal: Declined'],
  [DealActivityType.DEAL_DELETED, 'Deal: Deleted'],
  [DealActivityType.DEAL_DEAD, 'Deal: Dead'],
  [DealActivityType.PROGRAM_CREATED, 'Program: Created'],
  [DealActivityType.PROGRAM_UPDATED, 'Program: Updated'],
  [DealActivityType.PROGRAM_QUOTED, 'Program: Quoted'],
  [DealActivityType.PROGRAM_DELETED, 'Program: Deleted'],
  [DealActivityType.PROGRAM_SHARED, 'Program: Shared'],
  [DealActivityType.PROGRAM_PUBLISHED, 'Program: Specs Available'],
  [DealActivityType.BINDABLE_OPTIONS_AVAILABLE, 'Program: Bindable Options Available'],
  [DealActivityType.BINDABLE_SCENARIOS_AVAILABLE, 'Program: Bindable Scenarios Available'],
  [DealActivityType.MARKETING_ACTIVITY_AVAILABLE, 'Program: Marketing Activity Available'],
  [DealActivityType.PROGRAM_PUBLISHED_UNKNOWN, 'Program: Published Unknown'],
  [DealActivityType.FILE_UPDATED, 'File: Updated'],
  [DealActivityType.FILE_DELETED, 'File: Deleted'],
  [DealActivityType.FILE_UPLOADED, 'File: Uploaded'],
  [DealActivityType.FILE_DOWNLOADED, 'File: Downloaded'],
  [DealActivityType.REQUEST_FOR_CLEARANCE, 'Request for clearance'],
  [DealActivityType.BUILDERS_RISK_RENO_MARKETING, 'Builders Risk Reno Marketing'],
  [DealActivityType.SUBMISSION, 'Submission'],
  [DealActivityType.INDICATION_COMMENTED, 'Indication: Commented'],
  [DealActivityType.INDICATION_PROMOTED, 'Indication: Promoted'],
  [DealActivityType.INDICATION_RECEIVED, 'Indication: Received'],
  [DealActivityType.INDICATION_QUOTED, 'Indication: Quoted'],
  [DealActivityType.BIND_AUTH_CONFIRMATION, 'Bind Authorization: Confirmed'],
  [DealActivityType.BIND_AUTH_APPROVED, 'Bind Authorization: Approved'],
  [DealActivityType.BIND_AUTH_PROMOTED, 'Bind Authorization: Promoted'],
  [DealActivityType.BIND_ORDER_INITIATED, 'Bind Order: Initiated'],
  [DealActivityType.BIND_ORDER_CONFIRMATION, 'Bind Order: Confirmed'],
  [DealActivityType.REQUEST_BIND_ORDER, 'Request Bind Order'],
  [DealActivityType.BIND_ORDER_APPROVED, 'Bind Order: Approved'],
  [DealActivityType.TASK_CREATED, 'Task: Created'],
  [DealActivityType.TERMS_REJECTED, 'Term: Rejected'],
  [DealActivityType.TERMS_CARRIER_DECLINED, 'Term: Carrier Declined'],
  [DealActivityType.TERMS_DEAD, 'Term: Dead'],
  [DealActivityType.TERMS_DEAD_RFCLEARANCE, 'Term: Dead - Request for clearance'],
  [DealActivityType.TERMS_DEAD_INDICATED, 'Term: Dead - Indicated'],
  [DealActivityType.TERMS_DEAD_QUOTED, 'Term: Dead - Quoted'],
  [DealActivityType.TERMS_DEAD_SUBMITTED, 'Term: Dead - Submitted'],
  [DealActivityType.TERMS_DEAD_BIND_REQUESTED, 'Term: Dead - Bind Requested'],
  [DealActivityType.TERMS_DEAD_DECLINED, 'Term: Dead - Declined'],
  [DealActivityType.EMAIL_OPENED, 'Email: Opened'],
  [DealActivityType.EMAIL_CLICKED, 'Email: Clicked'],
  [DealActivityType.EMAIL_DELIVERED, 'Email: Delivered']
])

export const tryGetDealActivityLabel = (item: DealActivityType): string | DealActivityType => dealActivityKeyToLabelMap.get(item) || item

export const getDecoratedName = (deal: DealModel): string | undefined => `${deal.name}`
