import type { OrganizationModel } from '@/capability/organization/model'
import type { UserGroupDto, UserGroupUserDto } from 'typescript-core-api-client'
import { type CreateUserGroupDto } from 'typescript-core-api-client/dist/api'

export type UserGroupModel = UserGroupDto & {}

export type CreateUserGroupModel = CreateUserGroupDto & {}

export type MappedUserType = {
  id: string
  name?: string
  formattedName?: string
  created: string | Date
  type?: string
  organization?: OrganizationModel
  subRows?: MappedUserType[]
  isGroup?: boolean
  readOnly?: boolean
  depth?: number
  email?: string
  rawName?: string
  firstName?: string
  lastName?: string
  verticals?: string[]
  products?: string[]
}

export type MappedUserTypeClearingSubmission = MappedUserType & {
  to: { id: string; email: string; selected: boolean }
  cc: { id: string; email: string; selected: boolean }
}

export enum UserGroupUserType {
  Unknown = 'Unknown',
  Lead = 'Lead',
  AccountManager = 'AccountManager',
  AccountExecutive = 'AccountExecutive',
  Accounting = 'Accounting',
  Member = 'Member'
}

export type UserGroupUserModel = UserGroupUserDto & {}
