// const LOG = getLogger('string/stringUtil.ts')

export const isEmpty = (value: string | undefined | null): boolean => typeof value === 'undefined' || value == null || value === ''

export const isAbsent = (value: string | undefined | null): boolean => typeof value === 'undefined' || value == null || value === ''

export const isBlank = (value: string | undefined | null): boolean =>
  typeof value === 'undefined' || value == null || (typeof value === 'string' && value?.trim() === '')

export const isPresent = (value: string | undefined | null): boolean => !isAbsent(value)

export function truncateText(value: string, truncateLength: number, clamp = '...'): string {
  if (!value || value.length <= truncateLength || !truncateLength) return value
  return `${value.substring(0, truncateLength)}${clamp}`
}

export function appendSlashIfNeeded(input: string): string {
  if (input == null || input === '') {
    return input
  }

  if (input.endsWith('/')) {
    return input
  }
  return input + '/'
}

export function undefineIfEmpty(value: string | undefined | null): string | undefined {
  if (isEmpty(value)) {
    return undefined
  }
  if (value == null) {
    return undefined
  }
  return value
}

export function emptyStringIfEmpty(value: string | undefined | null): string | undefined {
  if (isEmpty(value)) {
    return ''
  }
  if (value == null) {
    return ''
  }
  return value.trim()
}

export function pascalCaseToSpaces(inputString: string): string | null | undefined {
  if (!inputString) return inputString
  // Use a regular expression to insert spaces before uppercase letters
  return inputString.replace(/([A-Z])/g, ' $1').trim()
}

export function pascalCaseToKebabCase(inputString: string): string | null | undefined {
  if (!inputString) return inputString
  // Use a regular expression to insert hyphens before uppercase letters
  const outputString = inputString.replace(/([A-Z])/g, '-$1').toLowerCase()
  return outputString.startsWith('-') ? outputString.substring(1) : outputString
}

export function removeArticles(value: any) {
  if (typeof value !== 'string') return value
  const words = value.split(' ')
  if (words.length <= 1) return value
  const articles = ['a', 'the', 'an']
  let index = 0
  while (index < words.length) {
    if (!articles.includes(words[index]?.toLowerCase())) {
      break
    } else {
      index++
    }
  }
  if (index === words.length) {
    return value
  }

  const spliced = words.splice(index).join(' ')
  if (!spliced.length) {
    return value
  }
  return spliced
}

export function stringCompareArticlesRemoved(a: string, b: string): number {
  const _a = removeArticles(a.toLowerCase())
  const _b = removeArticles(b.toLowerCase())

  if (_a > _b) return 1
  if (_a < _b) return -1
  return 0
}
