<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { useVModel } from '@vueuse/core'
import type { PrimitiveProps } from 'radix-vue'

import { cn } from '@/lib/utils'

import { textareaVariants } from '@/component/arqu-components/shadcn/ui/textarea/index'

interface Props extends PrimitiveProps {
  defaultValue?: string | number
  modelValue?: string | number
  variant?: NonNullable<Parameters<typeof textareaVariants>[0]>['variant']
  class?: HTMLAttributes['class']
  disabled?: boolean
  readonly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  defaultValue: '',
  modelValue: '',
  color: 'primary',
  variant: 'default',
  class: '',
  disabled: false,
  readonly: false
})

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>()

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue
})
</script>

<template>
  <textarea v-model="modelValue" :class="cn(textareaVariants({ variant }), props.class)" :disabled :readonly />
</template>
