import type { ProductModel } from '@/capability/products/types'
import { apiConfiguration } from '@/capability/system/Config'
import { ProductApi } from 'typescript-core-api-client'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export interface ProductsService {
  getProducts: () => Promise<ProductModel[]>
}

export const productsService = {
  getProducts: async function (): Promise<ProductModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const productApi = new ProductApi(config)

    const response = await productApi.list()
    return response.data.products ?? ([] as ProductModel[])
  }
} as ProductsService
