import { getUserDisplayName } from '@/lib/utils/user'

import type { OrganizationModel } from '@/capability/organization/model'
import type { UserMinimalModel, UserModel } from '@/capability/user/model'
import type { MappedUserType, UserGroupModel } from '@/capability/user-group/types'
import { UserGroupUserType } from '@/capability/user-group/types'

export const userGroupUserTypeLabels: Record<UserGroupUserType, string> = {
  [UserGroupUserType.Unknown]: 'Unknown',
  [UserGroupUserType.Lead]: 'Lead',
  [UserGroupUserType.AccountManager]: 'Account Manager',
  [UserGroupUserType.AccountExecutive]: 'Account Executive',
  [UserGroupUserType.Accounting]: 'Accounting'
}

export function getUserGroupUserTypeLabel(type: UserGroupUserType): string {
  return userGroupUserTypeLabels[type] || 'Unknown'
}

export function getUserGroupUserTypeFromLabel(label: string): UserGroupUserType {
  const entry = Object.entries(userGroupUserTypeLabels).find(([_, value]) => value === label)
  return entry ? (entry[0] as UserGroupUserType) : UserGroupUserType.Unknown
}

function getUserName(user: UserModel & UserMinimalModel): string {
  if (getUserDisplayName(user)) {
    return getUserDisplayName(user)
  }

  if (!user.email) {
    return ''
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName} (${user.email})`
  }

  return user.email
}

export const mapUserGroups =
  (organizations: OrganizationModel[]) =>
  (userGroup: UserGroupModel): MappedUserType => ({
    id: userGroup.id!,
    name: userGroup.name,
    rawName: userGroup.name,
    created: userGroup.dateCreatedMs ? new Date(userGroup.dateCreatedMs) : '',
    type: userGroup.groupType,
    organization: organizations.find((o) => o.id === userGroup.organizationId),
    subRows: userGroup.users?.map(mapUser(organizations, 1)),
    readOnly: userGroup.readOnly ?? false,
    isGroup: true,
    depth: 0
  })

export const mapUser =
  (organizations: OrganizationModel[], depth: number = 0) =>
  (user: UserModel & UserMinimalModel): MappedUserType => ({
    id: user.id!,
    formattedName: getUserName(user),
    name: user.name ?? '',
    created: user.dateCreated ? new Date(user.dateCreated) : user.dateCreatedMs ? new Date(user.dateCreatedMs) : '',
    type: user.userType?.[0] ?? '',
    organization: organizations.find((o) => o.id === user.organizationId),
    email: user.email ?? '',
    readOnly: user.readOnly ?? false,
    depth,
    rawName: `${user.firstName} ${user.lastName}`.trim(),
    firstName: user.firstName,
    lastName: user.lastName,
    verticals: user.verticals,
    products: user.products
  })
