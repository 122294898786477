const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export function formatDateTime(value?: number | string): string {
  if (!value) return ''
  const date = new Date(value.toString())
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
    timeZoneName: 'short'
  }).format(date)
}

export function decorateDateShort(date?: string | number, timeZone?: string): string {
  if (!date) {
    return ''
  }
  const _date = new Date(date)
  const userTimezoneOffset = _date.getTimezoneOffset() * 60000
  const dateWithTimezoneOffset = new Date(_date.getTime() + userTimezoneOffset)
  return !date
    ? ''
    : dateWithTimezoneOffset.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone
      })
}

export function decorateDateLong(date: string, timeZone?: string): string {
  const _date = new Date(date)
  const userTimezoneOffset = _date.getTimezoneOffset() * 60000
  const dateWithTimezoneOffset = new Date(_date.getTime() + userTimezoneOffset)
  return !date
    ? ''
    : dateWithTimezoneOffset.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        timeZone
      })
}

export const decorateDateTime = (date: string | number | undefined, timeZone?: string): string =>
  !date
    ? ''
    : new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone
      })

export const decorateDateJit = (date: string | number): string =>
  !date ? '' : new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })

export function formatDateForMessagesList(date: Date): string {
  const today = new Date()
  if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) {
    return date.toLocaleTimeString()
  }
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export function formatDateForMessageDetail(date: Date): string {
  return `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ${date.toLocaleTimeString([], {
    timeStyle: 'short'
  })}`
}

export function formatDateForInput(date?: Date): string {
  if (!date) return ''
  // Get date components
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0')

  // Get time components
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  // Create the datetime-local format string
  return `${year}-${month}-${day}T${hours}:${minutes}`
}
